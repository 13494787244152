import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from './../../store/actions';
import loginlogo from './../../images/connect-logo.svg';
import parentlogo from './../../images/parent-logo.svg'
import history from './../../history';
import Forgotpassword from './Forgotpassword';
import * as config from './../../config';

class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginerror: '',
            email: '',
            forgotpassModalIsOpen:false,
        }

        let url = this.props.match.url;
        //console.log(url.slice(7))
        // this.userType = this.props.match.params.user;
       // this.userType = url.slice(7); //get usertype from loginurl after '/login/'
        this.userType = 'professionals';
        localStorage.setItem('usertype', this.userType);
    }

    componentDidMount() 
    { 
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            const usertype = localStorage.getItem("usertype");

            const path  = config.userTypeRoutes[usertype]
            if (path) {
                history.push(path.basePath)
            }
            
        }
    }

    onPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onClickLogin = () => {
        this.props.submitLogin({
            email: this.state.username,
            password: this.state.password
        })
    }

    onClickSignup = () => {
        const path = config.userTypeRoutes[this.userType]
        if (path) {
            history.push(path.registerPath)
        }
        
    }

    componentWillReceiveProps(nextProps) {
        const loginifno = nextProps.login.logindata;
        if (loginifno) { 
            if (loginifno.status_code === 422 || loginifno.status_code === 403) {
                this.setState({
                    loginerror: "Please enter a valid Email Address and Password"
                });
            }
        }

    }

    

    onClickForpass = () => {
        this.setState({forgotpassModalIsOpen: true,});
    }

    closeForgotpasswordModal = () =>{
        this.setState({loginerror:'', username:'', password:'', forgotpassModalIsOpen: false})
    }

    render() {
        const { username, password} = this.state;
        return (

            <div className={localStorage.getItem("usertype") === "youth" ? "login-bg": "login-parent-bg"}>
                <div className={localStorage.getItem("usertype") === "youth" ? " login-container": " login-container1"}>
                    <div className="loginlogo"> <img src={localStorage.getItem("usertype") === "youth" ? loginlogo:parentlogo } className="logo" alt="logo" /></div>
                    {localStorage.getItem("usertype") === "youth" ? "" : <div className='logintagline'>Offering digital learning experiences focused on substance use, prevention, and intervention strategies for teens</div>}
                    <div className="row rowbox">
                        <div className="col-xl-12 errorhold"><span className="errormessage">{this.state.loginerror}</span></div>
                    </div>
                    <div className="row rowbox">
                        <div className="col-xl-12 col-lg-12 login-textbox-hold">
                            <div className="form-group">
                                <i className={localStorage.getItem("usertype") === "youth" ? "la la-envelope-o username":"la la-envelope-o username-parent" } ></i>
                                <input type="text" className={localStorage.getItem("usertype") === "youth" ? "form-control input-box" : "form-control input-parent-box focustext"} placeholder="Email Address" value={username}
                                    name="username" onChange={this.onPropertyChange} />
                            </div>
                            <div className="form-group">
                                <i className={localStorage.getItem("usertype") === "youth" ? "la la-lock password": "la la-lock password-parent" } ></i>
                                <input type="password" className={localStorage.getItem("usertype") === "youth" ? "form-control input-box":"form-control input-parent-box focustext" } placeholder="Password" value={password}
                                    name="password" onChange={this.onPropertyChange} />
                            </div>
                            <div className="row">
                                <div className="col-xl-5 col-5 forgotpassword-link"><span  onClick={this.onClickForpass} >Forgot Password?</span></div>
                                <div className="col-xl-7 col-7 signup-link">
                                    <span style={{color:'#fff',cursor:'default'}}>Don't have an account?</span><span onClick={this.onClickSignup}> SIGN UP </span> </div>
                                {/* <div className="col-xl-7 col-7"><span style={{color:'#b1d5ff'}}>Don't have an account? </span><span onClick={this.onClickSignup} style={{color:'#b1d5ff',cursor:'pointer'}}> SIGN UP </span> </div> */}

                            </div>
                            <input type="button"
                                value="SIGN IN"
                                className= {localStorage.getItem("usertype") === "parents" ? "btn btn-primary loginbuttonParent": "btn btn-primary loginbuttonParent"}
                                onClick={this.onClickLogin} />

                        </div>
                    </div>

                </div>

                <div className="footer">
                    {'Copyright © 2019. Connect 5. All rights reserved'}
            </div>

                {/* Open Forgotpassword modal */}
                {
                    this.state.forgotpassModalIsOpen ? <Forgotpassword
                        forgotpassModalIsOpen = {this.state.forgotpassModalIsOpen}
                        modalIsClose = {this.closeForgotpasswordModal}
                    ></Forgotpassword> :''
                }   
            </div>
        )
    }
}
//make it arrow func
function mapDispatchToProps(dispatch)  {
    return bindActionCreators({
        submitLogin: Actions.login,
        submitForgotpass: Actions.forgotpassword
    }, dispatch)
}

function mapStateToProps ({ loginUser }) {
    return {
        login: loginUser
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));

